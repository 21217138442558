<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img class="misc-tree" src="@/assets/images/misc/tree-2.png"></v-img>

    <div style="max-width: 600px" class="mx-auto h-full pa-10">
      <v-card>
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

          <h2 class="text-2xl font-weight-semibold">
            {{ appName }}
          </h2>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="font-weight-semibold text-base text--primary mb-2">Verificación de Escolaridad/Certificado</p>
        </v-card-text>
        <v-card-text>
          <v-form ref="verifiyForm" @submit.prevent="verify">
            <v-row>
              <v-col cols="12" md="4" sm="4">
                <v-select
                  label="Tipo"
                  v-model="verifyType"
                  :rules="[validators.required]"
                  outlined
                  dense
                  :items="verifTypes"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="8" sm="8">
                <v-text-field
                  v-model="verifyCode"
                  outlined
                  hide-details="auto"
                  dense
                  :error-messages="errorMessages"
                  :rules="[validators.required]"
                  placeholder="Ingresa el codigo de verificación"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="captchakey"
                  @verify="markRecaptchaAsVerified"
                  @expired="markRecaptchaAsExpired"
                >
                </vue-recaptcha>
              </v-col>

              <v-col cols="12">
                <v-btn :loading="buttonLoading" :disabled="buttonLoading" type="submit" color="primary">
                  Verificar <v-icon>{{ icons.mdiCheckboxMarkedCircleOutline }}</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <v-expand-transition>
        <v-card class="mt-7" v-if="scholarship && Object.keys(scholarship).length > 1">
          <v-card-title>
            <span
              >{{ scholarship.student.name }} {{ scholarship.student.lastname }}
              {{
                scholarship.student.document
                  ? ` - C.I ${scholarship.student.document.replace(/^(\d{1})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')} `
                  : ''
              }}</span
            >
          </v-card-title>
          <v-card-subtitle>
            <span class="text-subtitle-1">{{ scholarship.group.courseName }}</span>
          </v-card-subtitle>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Materia</th>
                  <th class="text-left">Calificación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in scholarship.subjects" :key="item.name">
                  <td>
                    <p class="font-weight-medium text--primary mb-n1">{{ item.name }}</p>
                  </td>
                  <td>{{ getQualification(item) }}</td>
                </tr>
              </tbody>
            </template>
            <template v-slot:no-data> No hay materias para mostrar </template>
          </v-simple-table>
        </v-card>
        <v-card
          class="mt-7"
          style="inline-size: 100%; margin-inline: auto; max-inline-size: 500px"
          v-else-if="scholarship && Object.keys(scholarship).length == 1"
        >
          <v-alert dense color="success" :icon="icons.mdiCheck" text>
            <p class="font-weight-semibold mb-1">
              La escolaridad es auténtica y corresponde a {{ scholarship.student.name }}
              {{ scholarship.student.lastname }}
              {{
                scholarship.student.document
                  ? ` C.I ${scholarship.student.document.replace(/^(\d{1})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')} `
                  : ''
              }}
            </p>
          </v-alert>
        </v-card>
        <v-card
          class="mt-7"
          style="inline-size: 100%; margin-inline: auto; max-inline-size: 500px"
          v-else-if="certificate"
        >
          <v-alert dense color="success" :icon="icons.mdiCheck" text>
            <p class="font-weight-semibold mb-1">
              ¡{{ certificate.student.name }} {{ certificate.student.lastname }}
              {{
                certificate.student.document
                  ? ` C.I ${certificate.student.document.replace(/^(\d{1})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')} `
                  : ''
              }}
              aprobó el Curso {{ certificate.group.courseName }}!
            </p>
          </v-alert>
        </v-card>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@core/utils/validation'
import { mdiCheck, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from 'vue'
import { ObjectID } from 'bson'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    const verifiyForm = ref(null)
    const verifyCode = ref(null)
    const scholarship = ref(null)
    const certificate = ref(null)
    const errorMessages = ref([])
    const buttonLoading = ref(false)
    const recaptcha = ref()
    const reCaptchaVerify = ref(false)
    const verifyType = ref(null)
    const verifTypes = [
      { text: 'Escolaridad', value: 1 },
      { text: 'Certificado', value: 2 },
    ]

    const captchakey =
      process.env.NODE_ENV === 'development'
        ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        : '6LdSndkUAAAAANie4F5zEp9SeYwbCvU-M_yEySIx'

    const markRecaptchaAsVerified = () => {
      reCaptchaVerify.value = true
    }

    const markRecaptchaAsExpired = () => {
      reCaptchaVerify.value = false
    }

    const verify = () => {
      const isFormValid = verifiyForm.value.validate()

      if (!isFormValid) return

      if (!reCaptchaVerify.value) {
        errorMessages.value.push('Compruebe el captcha')
        return
      }

      if (!ObjectID.isValid(verifyCode.value)) {
        errorMessages.value.push('El código ingresado no es válido')
        recaptcha.value.reset()
        return
      }

      buttonLoading.value = true
      errorMessages.value = []

      if (verifyType.value == 1) {
        axios
          .get(`/scholarship/verify/${verifyCode.value}`)
          .then(response => {
            scholarship.value = response.data.scholarship
            certificate.value = null
            buttonLoading.value = false
          })
          .catch(error => {
            buttonLoading.value = false
            errorMessages.value.push('El código ingresado no es válido')
          })
      } else {
        axios
          .get(`/certificate/verify/${verifyCode.value}`)
          .then(response => {
            certificate.value = response.data.certificate
            scholarship.value = null
            buttonLoading.value = false
          })
          .catch(error => {
            buttonLoading.value = false
            errorMessages.value.push('El código ingresado no es válido')
          })
      }

      verifiyForm.value.reset()
      recaptcha.value.reset()
    }

    const getQualification = subject => {
      let qualy = scholarship.value.group.qualifications.at(0).find(q => q.subject == subject._id)
      return qualy && qualy.qualification
        ? subject.qualificationType == 'course'
          ? qualy.qualification
          : qualy.qualification == 1
          ? 'Aprobado'
          : 'No Aprobado'
        : ''
    }

    return {
      verifTypes,
      verifyType,
      recaptcha,
      captchakey,
      verify,
      getQualification,
      verifyCode,
      scholarship,
      certificate,
      buttonLoading,
      errorMessages,
      icons: {
        mdiCheckboxMarkedCircleOutline,
        mdiCheck,
      },
      validators: {
        required,
      },
      VueRecaptcha,
      markRecaptchaAsVerified,
      markRecaptchaAsExpired,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      verifiyForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
